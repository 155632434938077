<template>
  <v-container fluid>
    <v-row class="mb-2">
      <v-col class="text-center text-h4 col-12">Поддержка</v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="tickets"
          :loading="loading"
          loading-text="Загрузка... Пожалуйста подождите"
          :items-per-page="25"
        >
          <template v-slot:item.user="{ item }">
            <router-link tag="div" :to="`/users/${item.cards.id}`">
              <v-btn color="primary"> {{ item.cards.nick }}</v-btn>
            </router-link>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              :color="
                item.status.id === 1
                  ? 'red'
                  : item.status.id === 1
                  ? 'green'
                  : 'blue'
              "
              >{{ item.status.value }}</v-chip
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link :to="`/support/tickets/${item.id}`"
              ><v-btn color="primary">Показать</v-btn></router-link
            >
            <v-btn
              v-show="[0, 1].indexOf(item.status) >= 0"
              class="ml-2"
              color="red"
              @click="closeTicket(item.id)"
              >close</v-btn
            >
          </template>
          <template v-slot:item.updatedAt="{ item }">
            {{ item.updatedAt | moment("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SupportService from "@/services/support.service";
export default {
  name: "Index",
  data: () => ({
    headers: [
      { text: "id", value: "id" },
      { text: "Пользователь", value: "user" },
      { text: "Тема", value: "title" },
      { text: "Статус", value: "status" },
      { text: "Дата", value: "updatedAt" },
      { text: "Действия", value: "actions" }
    ],
    tickets: [],
    loading: false
  }),
  methods: {
    closeTicket(ticketId) {
      SupportService.update({
        data: {
          state: 3
        },
        where: {
          id: ticketId
        }
      }).then(() => {
        this.loadTickets();
      });
    },
    loadTickets() {
      this.loading = true;
      SupportService.findMany({
        orderBy: [{ state: "asc" }, { id: "desc" }],
        take: 150,
        include: {
          cards: true,
          status: true
        }
      }).then(data => {
        this.tickets = data;
        this.loading = false;
      });
    }
  },
  created() {
    this.loadTickets();
  }
};
</script>

<style scoped></style>
